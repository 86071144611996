export default defineNuxtRouteMiddleware(async (to) => {
  const suggestedCountry = to.query["suggest-country"] as string;
  const suggestedLanguages = to.query["suggest-languages"] as string;

  if (!suggestedCountry && !suggestedLanguages) return;

  const user = useUser();

  let acceptSuggestedCountry = suggestedCountry && (!user.value || user.value?.accepting_suggestions.country);
  let acceptSuggestedLanguages = suggestedLanguages && (!user.value || user.value?.accepting_suggestions.languages);

  let suggestedData: { country?: string; languages?: string } = {};

  if (acceptSuggestedCountry) {
    if (user.value) user.value.accepting_suggestions.country = false;
    suggestedData.country = suggestedCountry;
  }

  if (acceptSuggestedLanguages) {
    if (user.value) user.value.accepting_suggestions.languages = false;
    suggestedData.languages = suggestedLanguages;
  }

  if (acceptSuggestedCountry || acceptSuggestedLanguages) {
    await $larafetch("user-suggestions", {
      method: "PUT",
      body: suggestedData,
    });
  }

  delete to.query["suggest-country"];
  delete to.query["suggest-languages"];

  const router = useRouter();
  await router.replace(to);
});
