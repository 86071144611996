import { library, config } from "@fortawesome/fontawesome-svg-core";

config.autoAddCss = false;

/**
 * Brand Icons.
 */
import { faCcMastercard, faCcVisa, faFacebook, faInstagram, faLinkedin, faMarkdown, faTiktok, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

library.add(faCcMastercard, faCcVisa, faFacebook, faInstagram, faLinkedin, faMarkdown, faTiktok, faTwitter, faWhatsapp);

/**
 * Duo tone Icons.
 */
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

library.add(faSpinnerThird);

/**
 * Light Icons.
 */
import { faRobot as faRobotLight, faSidebar } from "@fortawesome/pro-light-svg-icons";

library.add(faRobotLight, faSidebar);

/**
 * Regular Icons.
 */
import {
  faArrowUpRightFromSquare,
  faBan,
  faBars,
  faBuilding,
  faCalendar,
  faCheck,
  faCircleInfo,
  faCloudArrowDown,
  faCompress,
  faCookieBite,
  faCopy,
  faCreditCard,
  faEllipsisVertical,
  faEnvelope,
  faExpandWide,
  faEye,
  faFlagPennant,
  faGlobe,
  faHandPointRight,
  faHeart,
  faLightbulbOn,
  faLink,
  faLocationPin,
  faLongArrowRight,
  faMessage,
  faMessageSmile,
  faPalette,
  faPencil,
  faPlus,
  faQrcode,
  faRefresh,
  faRobot,
  faShip,
  faSparkles,
  faTimer,
  faUser,
  faUserCircle,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  faArrowUpRightFromSquare,
  faBan,
  faBars,
  faBuilding,
  faCalendar,
  faCheck,
  faCircleInfo,
  faCloudArrowDown,
  faCompress,
  faCookieBite,
  faCopy,
  faCreditCard,
  faEllipsisVertical,
  faEnvelope,
  faExpandWide,
  faEye,
  faFlagPennant,
  faGlobe,
  faHandPointRight,
  faHeart,
  faLightbulbOn,
  faLink,
  faLocationPin,
  faLongArrowRight,
  faMessage,
  faMessageSmile,
  faPalette,
  faPencil,
  faPlus,
  faQrcode,
  faRefresh,
  faRobot,
  faShip,
  faSparkles,
  faTimer,
  faUser,
  faUserCircle
);

/**
 * Solid Icons.
 */
import {
  faArrowRightFromBracket,
  faBackwardFast,
  faBookOpen,
  faChartSimple,
  faCheck as faCheckSolid,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCopy as faCopySolid,
  faCrown,
  faEnvelope as faEnvelopeSolid,
  faExclamation,
  faEye as faEyeSolid,
  faFileAlt,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFilePlus,
  faFilm,
  faGem,
  faHouse,
  faImageLandscape,
  faLink as faLinkSolid,
  faLongArrowLeft,
  faLongArrowRight as faLongArrowRightSolid,
  faMapMarker,
  faObjectsColumn,
  faPalette as faPaletteSolid,
  faPencil as faPencilSolid,
  faPlayCircle,
  faPhone,
  faPhotoVideo,
  faQrcode as faQrcodeSolid,
  faQuestion,
  faQuoteLeft,
  faRetweet as faRetweetSolid,
  faSearch,
  faShare,
  faStars,
  faTimes,
  faUserCircle as faUserCircleSolid,
  faWaveform,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faArrowRightFromBracket,
  faBackwardFast,
  faBookOpen,
  faChartSimple,
  faCheckSolid,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCopySolid,
  faCrown,
  faEnvelopeSolid,
  faExclamation,
  faEyeSolid,
  faFileAlt,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFilePlus,
  faFilm,
  faGem,
  faHouse,
  faImageLandscape,
  faLinkSolid,
  faLongArrowLeft,
  faLongArrowRightSolid,
  faMapMarker,
  faObjectsColumn,
  faPaletteSolid,
  faPencilSolid,
  faPlayCircle,
  faPhone,
  faPhotoVideo,
  faQrcodeSolid,
  faQuestion,
  faQuoteLeft,
  faRetweetSolid,
  faSearch,
  faShare,
  faStars,
  faTimes,
  faUserCircleSolid,
  faWaveform
);

export default defineNuxtPlugin(() => {
  //
});
