/**
 * Types
 */
type Modal = {
  component: ModalComponent;
  props: ModalProps;
  resolve(response: any): void;
};

export type ModalComponent = string | ConcreteComponent<{}, any, any, ComputedOptions, MethodOptions>;

type ModalProps = Record<string, any>;

type ModalResponse = string | number | Record<string, any>;

/**
 * Composeable
 */
const modals = shallowRef<Modal[]>([]);

const openModal = <T>(component: ModalComponent, props?: ModalProps) => {
  return new Promise<T | undefined>((resolve) => {
    modals.value.push({ component, props: { ...props }, resolve });
    triggerRef(modals);
  });
};

const closeModal = (response?: ModalResponse) => {
  const closedModal = modals.value.pop();
  closedModal?.resolve(response);
  triggerRef(modals);
};

const closeAllModals = (response?: ModalResponse) => {
  modals.value.forEach((modal, index) => {
    if (index === modals.value.length - 1) {
      modal.resolve(response);
    } else {
      modal.resolve(undefined);
    }
  });

  modals.value = [];
  triggerRef(modals);
};

const useModal = () => ({
  modals: shallowReadonly(modals),
  openModal,
  closeModal,
  closeAllModals,
});

export default useModal;
