import revive_payload_client_4sVQNw7RlN from "/buddy/inspires/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/buddy/inspires/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/buddy/inspires/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/buddy/inspires/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/buddy/inspires/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/buddy/inspires/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/buddy/inspires/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/buddy/inspires/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_client_KZ0f4ARq4r from "/buddy/inspires/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/buddy/inspires/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/buddy/inspires/plugins/auth.ts";
import cn_WJinXCj4S6 from "/buddy/inspires/plugins/cn.ts";
import fontawesome_cn2c4tOOHz from "/buddy/inspires/plugins/fontawesome.ts";
import markdown_nljPFoZ1ix from "/buddy/inspires/plugins/markdown.ts";
import modal_UEFnUXzgTX from "/buddy/inspires/plugins/modal.ts";
import plugin_auto_pageviews_client_cBh4f50wXK from "/buddy/inspires/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_client_KZ0f4ARq4r,
  chunk_reload_client_UciE0i6zes,
  auth_vT9JWWT9pN,
  cn_WJinXCj4S6,
  fontawesome_cn2c4tOOHz,
  markdown_nljPFoZ1ix,
  modal_UEFnUXzgTX,
  plugin_auto_pageviews_client_cBh4f50wXK
]