export default defineNuxtRouteMiddleware(async (to) => {
  if (to.fullPath.substring(0, 3) === "/s/") {
    let hashid;
    [hashid] = to.fullPath.substring(3).split("/");

    const canonicalUrl: string | null = await $larafetch(`/s/${hashid}/canonical`);

    if (canonicalUrl) {
      const unescapedCanonicalUrl = canonicalUrl.replace(/\\/g, "");
      await navigateTo(unescapedCanonicalUrl, { replace: true, external: true });
    }
  } else if (to.fullPath.substring(0, 18) === "/auroraexpeditions") {
    to.fullPath = to.fullPath.replace("/auroraexpeditions", "/aurora-expeditions");
  }
});
