const { openModal, closeModal, closeAllModals } = useModal();

export default defineNuxtPlugin(() => {
  return {
    provide: {
      modal: {
        open: openModal,
        close: closeModal,
        closeAll: closeAllModals,
      },
    },
  };
});
