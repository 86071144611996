const NewLineToBrFilter = (str: string): string => {
  if (typeof str === "undefined" || str === null) return "";
  return (str + "").replace(/(\r\n|\n\r|\r|\n)/g, "<br>" + "$1");
};

const markdown = (value: string): string => {
  if (!value) return "";

  value = value
    .replace(/[*]{2}([^*]+)[*]{2}/g, '<strong class="font-extrabold">$1</strong>')
    .replace(/[_]{2}([^_]+)[_]{2}/g, '<span class="underline">$1</span>')
    .replace(/[*]([^*]+)[*]/g, '<em class="italic">$1</em>')
    .replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '<a href="$2">$1</a>');

  return NewLineToBrFilter(value);
};

export default defineNuxtPlugin(() => {
  return {
    provide: {
      markdown,
    },
  };
});
