import { twMerge } from "tailwind-merge";
import { clsx } from "clsx";
import type { ClassValue } from "clsx";

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      cn,
    },
  };
});
