const afterLast = (string: string, search: string) => {
  const lastIndex = string.lastIndexOf(search);
  return string.substring(lastIndex + 1);
};

const getVersionHashidFromUrl = () => {
  return afterLast(useRoute().params.slug, "-");
};

export { afterLast, getVersionHashidFromUrl };
