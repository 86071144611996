export default defineNuxtPlugin(async () => {
  const user = useUser();

  if (user.value === undefined) {
    const response = await fetchCurrentUser();
    user.value = response?.data;
  }

  if (user.value) {
    useBugsnag().setUser(user.value.id, user.value.email, user.value.full_name);
  }
});
