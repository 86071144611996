<template>
  <Teleport to="body" v-if="modals.length > 0">
    <div class="relative z-50">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <component
          v-for="(modal, index) in modals"
          :key="index"
          :is="modal.component"
          ref="modalRefs"
          v-bind="modal.props"
          v-show="index === modals.length - 1"
          @click.stop
        />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
const { modals } = useModal();
</script>
