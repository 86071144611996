<template>
  <div
    v-if="cookieConsent !== 'accepted'"
    class="fixed inset-x-6 bottom-6 z-50 rounded-lg border bg-white p-6 text-sm font-medium shadow-lg md:left-auto md:max-w-80"
  >
    <div class="flex items-center space-x-3">
      <Icon icon="cookie-bite" prefix="far" class="text-3xl text-[#ea9654]" />
      <p>We use cookies to improve your experience. <NuxtLink to="/cookies">Learn More</NuxtLink></p>
    </div>
    <Button full-width size="sm" @click="acceptCookies" class="mt-4">Accept</Button>
  </div>
</template>

<script setup lang="ts">
import { useCookie } from "#app";

const cookieConsent = useCookie("cookie-consent", {
  domain: ".inspires.to",
  maxAge: 60 * 60 * 24 * 365,
  secure: true,
  sameSite: "lax",
});

const acceptCookies = () => {
  cookieConsent.value = "accepted";
};
</script>
