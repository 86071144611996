<template>
  <component
    :is="getComponent"
    :to="to"
    :type="type"
    :class="[
      `ag-button ag-button--color-${color} ag-button--variant-${variant}`,
      $cn(
        'inline-flex justify-center text-left font-bold no-underline transition-all focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
        {
          // Shape
          rounded: shape === 'rounded',
          'rounded-md': shape === 'rounded-md',
          'rounded-lg': shape === 'rounded-lg',
          'rounded-xl': shape === 'rounded-xl',
          'rounded-full': shape === 'pill',
          // Variant: Solid
          'shadow-3d bg-primary-500 text-white hover:bg-primary-600 hover:text-white': variant === 'solid' && color === 'primary',
          'shadow-3d bg-brand-500 text-white hover:bg-brand-600 hover:text-white': variant === 'solid' && color === 'brand',
          'shadow-3d bg-pink-500 text-pink-50 hover:bg-pink-600 hover:text-white': variant === 'solid' && color === 'pink',
          'shadow-3d bg-gray-100 text-gray-500 hover:bg-gray-200': variant === 'solid' && color === 'gray',
          'shadow-3d bg-white text-paragraph hover:bg-primary-600 hover:text-white': variant === 'solid' && color === 'white',
          // Variant: Outline
          'text-primary-500 ring-2 ring-inset ring-primary-300 hover:bg-primary-50/50 hover:text-primary-600 hover:ring-primary-400':
            variant === 'outline' && color === 'primary',
          'text-brand-500 ring-2 ring-inset ring-brand-300 hover:bg-brand-50/50': variant === 'outline' && color === 'brand',
          'text-pink-500 ring-2 ring-inset ring-pink-300 hover:bg-pink-50/50': variant === 'outline' && color === 'pink',
          'text-gray-500 ring-2 ring-inset ring-gray-300 hover:bg-gray-50/50 hover:ring-gray-400': variant === 'outline' && color === 'gray',
          'text-white ring-2 ring-inset ring-white hover:bg-white hover:text-paragraph': variant === 'outline' && color === 'white',
          // Variant: Ghost
          'bg-primary-50 text-primary-500 hover:bg-primary-100': variant === 'ghost' && color === 'primary',
          'bg-brand-50 text-brand-500 hover:bg-brand-100': variant === 'ghost' && color === 'brand',
          'bg-pink-50 text-pink-500 hover:bg-pink-100': variant === 'ghost' && color === 'pink',
          'bg-gray-100 text-gray-500 hover:bg-gray-200': variant === 'ghost' && color === 'gray',
          'hover:bg-white-100 text-text-paragraph bg-white hover:bg-primary-600 hover:text-white': variant === 'ghost' && color === 'white',
          // Variant: Link
          'text-primary-500 hover:text-primary-600': variant === 'link' && color === 'primary',
          'text-brand-500 hover:text-brand-600': variant === 'link' && color === 'brand',
          'text-pink-500 hover:text-pink-600': variant === 'link' && color === 'pink',
          'text-gray-500 hover:text-gray-600': variant === 'link' && color === 'gray',
          'hover:text-white-600 text-white': variant === 'link' && color === 'white',
          // Icon
          'items-center justify-center': icon,
          'p-3 text-xs': size === 'sm' && icon,
          'p-3.5 text-sm': size === 'md' && icon,
          'p-4 text-sm': size === 'lg' && icon,
          // Size
          'text-xs': size === 'sm',
          'text-sm': ['md', 'lg'].includes(size),
          'px-4 py-2.5': size === 'sm' && !icon && variant !== 'link',
          'px-5 py-3': size === 'md' && !icon && variant !== 'link',
          'px-6 py-4': size === 'lg' && !icon && variant !== 'link',
          'flex w-full': fullWidth,
        },
        ui?.base
      ),
    ]"
  >
    <span class="inline-flex">
      <span v-if="hasPrepend" :class="$cn('mr-3', ui?.prepend)">
        <slot name="prepend">
          <span v-if="prepend && !prependIcon">{{ prepend }}</span>
          <Icon v-if="prependIcon && !prepend" :icon="prependIcon" :prefix="prependIconPrefix" />
        </slot>
      </span>

      <Icon v-if="icon" :icon="icon" :prefix="iconPrefix" />

      <span>
        <slot />
      </span>
    </span>

    <span v-show="hasAppend" :class="$cn('ml-3', ui?.append)">
      <slot name="append">
        <span v-if="append && !appendIcon">{{ append }}</span>
        <Icon v-else-if="!append && appendIcon" :icon="appendIcon" :prefix="appendIconPrefix" />
      </slot>
    </span>
  </component>
</template>

<script setup lang="ts">
import type { IconPrefix } from "@fortawesome/fontawesome-svg-core";
import type { ClassValue } from "clsx";

export type ButtonColor = "primary" | "brand" | "pink" | "gray" | "white";
export type ButtonVariant = "solid" | "outline" | "ghost" | "link";
export type ButtonShape = "square" | "rounded" | "rounded-md" | "rounded-lg" | "rounded-xl" | "pill";

export type ButtonProps = {
  // Button Props
  as?: "button" | "a";
  color?: ButtonColor;
  variant?: ButtonVariant;
  shape?: ButtonShape;
  size?: "sm" | "md" | "lg";
  fullWidth?: boolean;
  submit?: boolean;
  // Icon
  icon?: string;
  iconPrefix?: IconPrefix;
  // Prepend Props
  prepend?: string;
  prependIcon?: string;
  prependIconPrefix?: IconPrefix;
  // Append Props
  append?: string;
  appendIcon?: string;
  appendIconPrefix?: IconPrefix;
  // Button Props
  to?: string;
  // UI classes
  ui?: Partial<{
    base: ClassValue;
    append: ClassValue;
    prepend: ClassValue;
  }>;
};

const props = withDefaults(defineProps<ButtonProps>(), {
  // Button Props
  color: "primary",
  variant: "solid",
  shape: "rounded-md",
  size: "md",
  fullWidth: false,
  // Icon
  iconPrefix: "far",
  // Prepend Props
  prependIconPrefix: "far",
  // Append Props
  appendIconPrefix: "far",
});

const slots = useSlots();

const type = computed(() => {
  if (props.as !== "button") return;
  return props.submit ? "submit" : "button";
});

const hasAppend = computed(() => slots.append || props.append || props.appendIcon);
const hasPrepend = computed(() => slots.prepend || props.prepend || props.prependIcon);

const getComponent = computed(() => {
  if (props.to) {
    return resolveComponent("NuxtLink");
  } else if (props.as) {
    return props.as;
  }

  return "button";
});
</script>
