export default defineNuxtRouteMiddleware(async (to) => {
  const { backendUrl, frontendUrl } = useRuntimeConfig().public;
  const uriSegments = to.path.split("/").filter(Boolean);

  // Collaborator routes
  if (uriSegments[0] === "share") {
    const collaboratorSlug = uriSegments[1];
    let url;

    if (uriSegments[1] && uriSegments[2] === "copilot") {
      const response = await $larafetch<{ url: string }>(`${backendUrl}/share/${collaboratorSlug}/copilot`, {
        query: {
          email: to.query.email,
          url: to.query.url,
          first_name: to.query.first_name,
          last_name: to.query.last_name,
        },
      });

      if (response.url) {
        url = response.url;
      }
    } else if (collaboratorSlug && uriSegments[2].includes("-")) {
      const collectionHashid = afterLast(uriSegments[2], "-");

      const response = await $larafetch<{ url: string }>(`${backendUrl}/share/${collaboratorSlug}/${collectionHashid}`, {
        query: {
          email: to.query.email,
          first_name: to.query.first_name,
          last_name: to.query.last_name,
        },
      });

      if (response.url) {
        url = response.url;
      }
    }

    if (url) {
      return navigateTo(url, { external: true, replace: true });
    }
  }
});
