import { $fetch, FetchOptions, FetchError } from "ofetch";

const CSRF_COOKIE = "XSRF-TOKEN";
const CSRF_HEADER = "X-XSRF-TOKEN";

// could not import these types from ofetch, so copied them here
interface ResponseMap {
  blob: Blob;
  text: string;
  arrayBuffer: ArrayBuffer;
}
type ResponseType = keyof ResponseMap | "json";
// end of copied types

export type LarafetchOptions<R extends ResponseType> = FetchOptions<R> & {
  redirectIfNotAuthenticated?: boolean;
  redirectIfNotVerified?: boolean;
};

export async function $larafetch<T, R extends ResponseType = "json">(
  path: RequestInfo,
  { redirectIfNotAuthenticated = true, redirectIfNotVerified = true, ...options }: LarafetchOptions<R> = {}
) {
  const { backendUrl, frontendUrl } = useRuntimeConfig().public;
  const router = useRouter();

  let token = useCookie(CSRF_COOKIE).value;

  // on client initiate a csrf request and get it from the cookie set by laravel
  if (process.client && ["post", "delete", "put", "patch"].includes(options?.method?.toLowerCase() ?? "")) {
    await initCsrf();
    // cannot use nuxt composables such as useCookie after an async operation:
    // https://github.com/nuxt/framework/issues/5238
    token = getCookie(CSRF_COOKIE);
  }

  let headers: any = {
    accept: "application/json",
    ...options?.headers,
    ...(token && { [CSRF_HEADER]: token }),
  };

  if (process.server) {
    headers = {
      ...headers,
      ...useRequestHeaders(["Accept-Language", "cookie", "X-Forwarded-For"]),
      referer: frontendUrl,
    };
  }

  try {
    if (options.body) {
      const data = options.body || {};
      const hasFile = computed(() => Object.values(data).some((value) => value instanceof File));
      const originalMethod = options.method;
      options.method = hasFile.value ? "POST" : options.method;
      options.body = hasFile.value ? await buildFormData(originalMethod, data) : data;
    }

    return await $fetch<T, R>(path, {
      baseURL: backendUrl,
      ...options,
      headers,
      credentials: "include",
    });
  } catch (error) {
    if (!(error instanceof FetchError)) throw error;

    // when any of the following redirects occur and the final throw is not caught then nuxt SSR will log the following error:
    // [unhandledRejection] Error [ERR_HTTP_HEADERS_SENT]: Cannot set headers after they are sent to the client

    const status = error.response?.status ?? -1;

    if (redirectIfNotAuthenticated && [401, 419].includes(status)) {
      await router.push("/login");
    }

    if (redirectIfNotVerified && [409].includes(status)) {
      await router.push("/verify-email");
    }

    if ([500].includes(status)) {
      console.error("[Laravel Error]", error.data?.message, error.data);
    }

    throw error;
  }
}

async function initCsrf() {
  const { backendUrl } = useRuntimeConfig().public;

  await $fetch("/auth/csrf-cookie", {
    baseURL: backendUrl,
    credentials: "include",
  });
}

// https://github.com/axios/axios/blob/bdf493cf8b84eb3e3440e72d5725ba0f138e0451/lib/helpers/cookies.js
function getCookie(name: string) {
  const match = document.cookie.match(new RegExp("(^|;\\s*)(" + name + ")=([^;]*)"));
  return match ? decodeURIComponent(match[3]) : null;
}

/**
 * Helpers
 */
const parseValue = (value) => {
  if (typeof value === "undefined") {
    // Return/do nothing. Prevents sending undefined values in FormData which subsequently breaks Laravel array validation.
    return;
  } else if (typeof value === "boolean") {
    value = value ? "1" : "0";
  } else if (value === null) {
    value = "";
  } else if (typeof value.getMonth === "function") {
    value = value.toUTCString();
  }

  return value;
};

const buildFormData = async (method: FormMethod, data: FormData) => {
  let formData = new FormData();
  formData.append("_method", method);

  for (let [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      value.forEach((item) => formData.append(`${key}[]`, parseValue(item)));
    } else {
      formData.append(key, parseValue(value));
    }
  }

  return formData;
};
